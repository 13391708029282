import * as React from "react"
import { graphql, Link } from "gatsby"
import Episode from "../components/episode"

import Layout from "../components/layout"
import { Seo } from "../components/seo"

const IndexPage = ({data}) => {
  return (
    <Layout>
      <div style={{"display": "flex", "paddingBottom": `var(--space-3)`, "justify-content": "space-between", "align-items": "center"}}>
        <h1 style={{}}>최근 에피소드</h1>
        <div>
          <Link to="/episodes" style={{"display": "flex", "align-items": "center"}}>
            <span>전체 에피소드</span>
            <div class="link-arrow"></div>
          </Link>
        </div>
      </div>
      {
        data.allFile.nodes.map((node) => (
          <Episode key={node.id} node={node}></Episode>
        )) 
      }
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(
      filter: {sourceInstanceName: {eq: "episodes"}, childMdx: {id: {ne: null}}}
      sort: {fields: [childrenMdx___frontmatter___published_date, childrenMdx___frontmatter___uid], order: [DESC, DESC]}
      limit: 5
    ) {
      nodes {
        id
        childMdx {
          frontmatter {
            title
            uid
            recording_date
            published_date
            duration
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

export const Head = () => (
  <Seo />
)
