import { Link } from "gatsby"
import * as React from "react"
import { episode, info, title } from "./episode.module.css"

const Episode = ({ node }) => {
  return (
    <div className={episode}>
      <div key={node.id}>
        <h2 className={title}>
          <Link to={`/episodes/${node.childMdx.frontmatter.uid}`}>
            [{node.childMdx.frontmatter.uid}] {node.childMdx.frontmatter.title}
          </Link>
        </h2>
        <div className={info}>
          {node.childMdx.frontmatter.published_date} | {node.childMdx.frontmatter.duration}
        </div>
      </div>
    </div>
  )
}

export default Episode
